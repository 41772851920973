export const environment = {
production: false,
GOOGLE_NOTES:"https://script.google.com/macros/s/AKfycbwk4wVPu0w1VFuR9764QgxLzuz7ENqzOoN9EBOVGVX8RSb0G-w/exec",
GOOGLE_SUPPLY:"https://script.google.com/macros/s/AKfycbzn5mKuDDyD_pljcq8lLdGoN5IGraPCeAoJf55hH_v1HTE4YcB1/exec",
GOOGLE_DEFINITIONS:"https://script.google.com/macros/s/AKfycbzb4SLOmmQLN2L_zf7eOpqYEFL3a7_vQljsCbg0CWaLOl0Wq92D/exec",
GOOGLE_INPUT_TOOL:"https://script.google.com/macros/s/AKfycbxim5lBkuQy6Wg5-aweuamBp4vi_DqWgkute3XLH9AXwFnQ1p0/exec",
ENVIRONMENT:"production",
BIGQUERY_ENDPOINT:"https://amis-portal.appspot.com/fetch",
BIGQUERY_DATASET: "fao-maps-review",
GA: "",
};